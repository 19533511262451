export const copyClipboardMixin = {
  methods: {
    // when copying from a modal context, pass an element from the modal in as the rootElement
    localCopyToClipboard(copyText, rootElement) {
      const tempInput = document.createElement('textarea')
      tempInput.innerHTML = ''
      tempInput.value = copyText
      rootElement ? rootElement.appendChild(tempInput) : document.body.appendChild(tempInput)
      tempInput.select()
      document.execCommand('copy')
      rootElement ? rootElement.removeChild(tempInput) : document.body.removeChild(tempInput)
    },
    async copyToClipboard(copyText, rootElement = null, successCallback, errorCallback) {
      if (navigator.clipboard) {
        try {
          await navigator.clipboard.writeText(copyText)
          successCallback('Success', 'Copied to clipboard')
        } catch (error) {
          errorCallback('Error', 'Could not copy to clipboard')
        }
      } else {
        this.localCopyToClipboard(copyText, rootElement)
        successCallback('Success', 'Copied to clipboard')
      }
    },
  },
}
